import React from 'react'
import { Footer, Header } from 'components/layout'
import { Primary, About, Advantages, OurStores, FrequentlyAsked, JoinUs } from 'components/index'

export default function Index() {
	return (
		<>
			<Header />
			<main>
				<Primary />
				<About />
				<Advantages />
				<OurStores />
				<FrequentlyAsked />
				<JoinUs />
			</main>
			<Footer />
		</>
	)
}
