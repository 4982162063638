import React, { forwardRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, FreeMode, Autoplay } from 'swiper'
import { mediaBreakpoints } from 'constants/mediaBreakpoints'
import cn from 'classnames'
import NextBtn from './NextBtn'
import PrevBtn from './PrevBtn'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import styles from './carousel.module.sass'

const Carousel = forwardRef(
	(
		{
			children,
			className,
			navigation,
			breakpoints,
			...rest
		},
		ref
	) => {
		const classList = cn(
			styles.carousel,
			className
		)

		const modulesList = []

		if (rest.pagination) {
			modulesList.push(Pagination)
		}
		if (rest.freeMode) {
			modulesList.push(FreeMode)
		}
		if (rest.autoplay) {
			modulesList.push(Autoplay)
		}

		const breaks = {}

		if (breakpoints && typeof breakpoints === 'object') {
			for (const [key, value] of Object.entries(breakpoints)) {
				if (mediaBreakpoints[key]) {
					breaks[mediaBreakpoints[key]] = value
				}
			}
		}

		return (
			<div className={classList}>
				<Swiper
					ref={ref}
					modules={modulesList}
					navigation={false}
					breakpoints={breaks}
					{...rest}
				>
					{navigation && (
						<div className={styles.navigation}>
							<PrevBtn />
							<NextBtn />
						</div>
					)}
					{children}
				</Swiper>
			</div>
		)
	}
)

const SlideItem = SwiperSlide

export { Carousel, SlideItem }
