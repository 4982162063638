import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import { Text } from 'components/ui'

const AnchorLink = ({
	to,
	title,
	size,
	color,
	weight,
	offset,
	smooth,
	...rest
}) => {
	return (
		<Link
			to={to}
			offset={offset}
			spy={true}
			smooth={smooth}
			{...rest}
		>
			<Text size={size} color={color} weight={weight}>{title}</Text>
		</Link>
	)
}

AnchorLink.defaultProps = {
	size: 'md',
	color: 'white',
	offset: -75,
	smooth: true
}

AnchorLink.propTypes = {
	to: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	size: PropTypes.string,
	color: PropTypes.string,
	weight: PropTypes.string,
	offset: PropTypes.number,
	smooth: PropTypes.bool
}

export default AnchorLink
