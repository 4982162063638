import React from "react";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { Section, Text, Collapse, FadeIn } from "components/ui";
import peopleImg from "assets/index/frequentlyAsked/people.png";
import blueQuestionImg from "assets/index/frequentlyAsked/blue.svg";
import orangeSmallQuestionImg from "assets/index/frequentlyAsked/orange-small.svg";
import greenSmallQuestionImg from "assets/index/frequentlyAsked/green.svg";
import orangeQuestionImg from "assets/index/frequentlyAsked/orange.svg";
import redQuestionImg from "assets/index/frequentlyAsked/red.svg";
import styles from "./frequently-asked.module.sass";
import { useTranslation } from "react-i18next";

const FrequentlyAsked = () => {
	const { deviceWidth } = useWindowSize();
	const { t } = useTranslation();

	const data = [
		{
			question: t("В чём плюсы покупки на ZumZak?"),
			answer: t(
				"Покупать здесь удобно, быстро и выгодно. Вы можете выбрать самое выгодное предложение на конкретный товар или найти самый ближайший к дому магазин с любимыми продуктами, и это – не выходя из дома и не теряя много времени и сил. Комфортное приложение позволит тратить на покупки минимум времени с максимумом пользы. "
			),
		},
		{
			question: t("Как найти нужный мне магазин? "),
			answer: t(
				"Если вы знаете название магазина, то сделать это быстрее поможет поиск, для этого введите наименование в поисковую строку. Даже если вы немного ошиблись или запомнили название неточно, поиск поможет вам и предоставит все возможные варианты. В случае, когда вы обладаете информацией только о категории товаров, например, что это – магазин косметики, стоит посетить соответствующий раздел каталога, возможно, вы вспомните логотип или наименование. Если же вам, например, нужно срочно купить продукты, то для этого лучшим вариантом для выбора магазина будет поиск по локации – в этом случае вам будут предложены ближайшие к вам продавцы, а это значит, что вы максимально быстро получите свой заказ. "
			),
		},
		{
			question: t("Как оформить заказ? "),
			answer: t(
				"Выберите нужный вам магазин, поместите товары в корзину и завершите оформление, заполнив всю необходимую контактную информацию. Также вам будет предложен выбор различных способов оплаты и доставки. \n \n Можно ли купить в одном заказе товары в разных магазинах? \n \n Нет, товары из разных магазинов оформляются отдельными заказами.  "
			),
		},
		{
			question: t("Можно ли купить в одном заказе товары в разных магазинах? "),
			answer: t(
				"Нет, товары из разных магазинов оформляются отдельными заказами."
			),
		},
		{
			question: t("Как оплатить заказ? "),
			answer: t(
				"Для оплаты заказов доступны несколько возможных вариантов, вы можете выбрать наиболее удобный для вас. "
			),
		},
		{
			question: t("Как мне доставят заказ? "),
			answer: t(
				"Доступно несколько вариантов доставки. Из предложенных вариантов вы можете выбрать комфортный для вас способ. "
			),
		},
		{
			question: t("Как я могу повторить заказ? "),
			answer: t(
				"Для сохранения истории покупок вам нужно пройти регистрацию, после чего вы сможете пользоваться функционалом личного кабинета: просматривать заказы, по необходимости – повторять, отслеживать их статусы, оставлять отзывы, составлять список избранных товаров. "
			),
		},
	];

	return (
		<Section
			id="faq"
			className="offset-top-30 offset-xl-top-180"
			style={{ zIndex: 1 }}
			relative
		>
			<div className="container">
				<div className="row row-30 align-items-end">
					<div className="col-xl-6 col-xxl-7">
						<FadeIn>
							<Text
								as="h2"
								color="blue-dark"
								align={deviceWidth === "large" ? "start" : "center"}
							>
								{t("Самые частые вопросы покупателей?")}
							</Text>
							<Collapse className="offset-top-30">
								{data.map((v, i) => (
									<Collapse.Panel key={i} label={v.question} index={i}>
										<Text
											size={deviceWidth === "large" ? "sm" : "xs"}
											whiteSpace="pre-line"
										>
											{v.answer}
										</Text>
									</Collapse.Panel>
								))}
							</Collapse>
						</FadeIn>
					</div>
					<div className="col-xl-6 col-xxl-5 order-xl-first">
						<FadeIn
							className={styles["people-wrapper"]}
							fade="left"
							delay={0.5}
						>
							<div className={styles["people-container"]}>
								<img className={styles.people} src={peopleImg} alt="" />
								<img
									className={cn(styles.question, styles.blue)}
									src={blueQuestionImg}
									alt=""
								/>
								<img
									className={cn(styles.question, styles["orange-small"])}
									src={orangeSmallQuestionImg}
									alt=""
								/>
								<img
									className={cn(styles.question, styles.green)}
									src={greenSmallQuestionImg}
									alt=""
								/>
								<img
									className={cn(styles.question, styles.orange)}
									src={orangeQuestionImg}
									alt=""
								/>
								<img
									className={cn(styles.question, styles.red)}
									src={redQuestionImg}
									alt=""
								/>
							</div>
						</FadeIn>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default FrequentlyAsked;
