import React from 'react';
import styled from "styled-components";
import privacyPdf from '../../assets/pdf/privacyPolicy.pdf'
import axios from "axios";

const Root = styled.div`
	width: 100vw;
	height: 100vh;

	iframe {
		width: 100vw;
		height: 100vh;
	}
`

const PrivacyPolicy = () => {


	return (
		<Root>
			<iframe title="Политика конфиденциальности" src={privacyPdf}/>
		</Root>
	);
};

export default PrivacyPolicy;
