import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import useDebounce from 'hooks/useDebounce'
import useWindowSize from 'hooks/useWindowSize'
import { Icon, Text } from 'components/ui'
import styles from './collapse.module.sass'

const Panel = ({
	children,
	label,
	defaultActiveTab,
	index,
	disabled,
	options,
	icon,
	ellipsis
}) => {
	const { deviceWidth, width } = useWindowSize()
	const debouncedWidth = useDebounce(width, 500)
	const [height, setHeight] = useState(0)
	const [activeTab, setActiveTab] = useState(defaultActiveTab)
	const isActive = activeTab === index
	const panelRef = useRef(null)
	const contentRef = useRef(null)

	useEffect(() => {
		const height = contentRef.current.lastChild.clientHeight
		setHeight(height)
	}, [isActive, debouncedWidth])

	const panelClassList = cn(
		styles.panel,
		{
			[styles.active]: isActive
		}
	)

	const innerStyle = {
		height: `${isActive ? height : 0}px`
	}

	const activateTab = tab => setActiveTab(activeTab === tab ? -1 : tab)

	return (
		<div
			ref={panelRef}
			className={panelClassList}
		>
			<button
				className={styles.panel__label}
				onClick={() => activateTab(index)}
				disabled={disabled}
			>
				<Text
					size={deviceWidth === 'large' ? 'md' : 'sm'}
					overflow={ellipsis ? 'ellipsis' : null}
				>
					{label}
				</Text>
				<div className={styles.panel__options}>
					<div
						className={styles['panel__additing-options']}
						onClick={(e) => e.stopPropagation()}
					>
						{options}
					</div>
					<Icon name={icon} className={styles.arrow} />
				</div>
			</button>
			<div
				ref={contentRef}
				className={styles.panel__inner}
				style={innerStyle}
			>
				<div className={styles.panel__content}>{children}</div>
			</div>
		</div>
	)
}

Panel.defaultProps = {
	icon: 'plus'
}

Panel.propTypes = {
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]).isRequired,
	defaultActiveTab: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	index: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	options: PropTypes.shape({}),
	icon: PropTypes.string,
	ellipsis: PropTypes.bool
}

export default Panel
