import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './icon.module.sass'

const Icon = ({
	children,
	className,
	name,
	size,
	color,
	style,
	title,
	hoverColor,
	turnOff,
	cursorPointer,
	...props
}) => {

	const classNames = cn(
		'icon-' + name,
		{
			[styles[color]]: color,
			[styles[size]]: size,
			[styles.hover]: hoverColor,
			[styles['hover-' + hoverColor]]: hoverColor,
			[styles['turn-off']]: turnOff,
			[styles.pointer]: cursorPointer
		},
		className
	)

	if (!name) return null

	return (
		<i
			className={classNames}
			style={style}
			title={title}
			{...props}
		/>
	)
}

Icon.defaultProps = {
	color: 'primary'
}

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm', 'xs']),
	color: PropTypes.oneOf(['primary', 'white', 'gray', 'blue', 'gray-light']),
	title: PropTypes.string,
	style: PropTypes.object,
	hoverColor: PropTypes.oneOf(['gray']),
	turnOff: PropTypes.bool,
	cursorPointer: PropTypes.bool
}

export default Icon
