import React, { useState, useEffect } from 'react'
import Drawer from 'rc-drawer'
import PropTypes from 'prop-types'
import useWindowSize from 'hooks/useWindowSize'
import styles from './control-menu.module.sass'

const ControlMenu = ({
	children,
	placement,
	width,
	isOpen,
	handler,
	level,
	onClose
}) => {
	const [drawerWidth, setDrawerWidth] = useState(width)
	const { width: currentWidth } = useWindowSize()

	useEffect(() => {
		if (!width) {
			if (currentWidth <= 700) {
				setDrawerWidth(`${currentWidth}px`)
			} else {
				setDrawerWidth('500px')
			}
		}
	}, [width, currentWidth])

	return (
		<Drawer
			placement={placement}
			width={drawerWidth}
			open={isOpen}
			handler={handler}
			level={level}
			onClose={onClose}
		>
			<div className={styles['control-menu']}>
				{isOpen ? children : ''}
			</div>
		</Drawer>
	)
}

ControlMenu.defaultProps = {
	placement: 'right',
	handler: false,
	level: ''
}

ControlMenu.propTypes = {
	placement: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
	width: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	handler: PropTypes.bool,
	level: PropTypes.oneOf(['', 'all']),
	onClose: PropTypes.func.isRequired
}

export default React.memo(ControlMenu)
