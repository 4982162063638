"use strict";
exports.__esModule = true;
exports.useKey = void 0;
var react_1 = require("react");
var useKey = function (key, cb) {
    var callBackRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        callBackRef.current = cb;
    }, []);
    (0, react_1.useEffect)(function () {
        function handle(event) {
            if (event.code === key) {
                callBackRef.current(event);
            }
        }
        document.addEventListener("keydown", handle);
        return function () { return document.removeEventListener("keydown", handle); };
    });
};
exports.useKey = useKey;
