import React, { useState } from 'react'
import FilterCategories from './FilterCategories'
import { ControlMenu, Icon, SearchForm } from 'components/ui'
import styles from './filter-categories.module.sass'

const FilterCategoriesMobile = (props) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className={styles['filter-categories-mobile']}>
			<div className={styles['filter-categories-mobile__icon-open']}>
				<Icon
					name='list'
					size='sm'
					color='white'
					onClick={() => setIsOpen(true)}
				/>
			</div>
			<ControlMenu isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<div className={styles['control-menu']}>
					<div className={styles['control-menu__header']}>
						<Icon
							name='close'
							size='xs'
							onClick={() => setIsOpen(false)}
							className={styles['icon-close']}
						/>
						<SearchForm
							searchButton={false}
							overlay={false}
							placeholder='Поиск'
							view='categories'
							className={styles['search']}
						/>
					</div>
					<div className={styles['control-menu__content']}>
						<FilterCategories {...props} mobile />
					</div>
				</div>
			</ControlMenu>
		</div>
	)
}

export default FilterCategoriesMobile
