import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Index from 'pages/Index'
import Categories from 'pages/Categories'
import PrivacyPolicy from "components/privecy/PrivacyPolicy";
import TermsOfUse from "components/privecy/TermsOfUse";
import NoticeDisclaimer from "components/privecy/NoticeDisclaimer";

export default function Router() {
	return (
		<Routes>
			<Route index element={<Index/>}/>
			<Route path='categories' element={<Categories/>}/>
			<Route path='*' element={<p>404</p>}/>
			<Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
			<Route path='/terms-of-use' element={<TermsOfUse/>}/>
			<Route path='/notice-disclaimer' element={<NoticeDisclaimer/>}/>
		</Routes>)
}
