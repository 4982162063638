import React from 'react';
import styled from "styled-components";
import noticeDisclaimer from '../../assets/pdf/noticeDisclaimer.pdf';


const Root = styled.div`
	width: 100vw;
	height: 100vh;

	iframe {
		width: 100vw;
		height: 100vh;
	}
`

const NoticeDisclaimer = () => {

	return (
		<Root>
			<iframe title="Политика конфиденциальности" src={noticeDisclaimer}/>
		</Root>
	);
};

export default NoticeDisclaimer;
