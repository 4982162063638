import PropTypes from 'prop-types'
import cn from 'classnames'

const Section = ({
	children,
	className,
	style,
	first,
	small,
	last,
	relative,
	backgroundImg,
	...rest
}) => {
	const classNames = cn(
		'section',
		{
			'section-first': first,
			'section-small': small,
			'section-last': last,
		},
		className
	)

	style = {
		...style,
		position: relative && 'relative'
	}

	return (
		<section
			className={classNames}
			style={style}
			{...rest}
		>
			{children}
		</section>
	)
}

Section.propTypes = {
	className: PropTypes.string,
	style: PropTypes.shape({}),
	first: PropTypes.bool,
	last: PropTypes.bool,
	small: PropTypes.bool,
	relative: PropTypes.bool,
	backgroundImg: PropTypes.string
}

export default Section
