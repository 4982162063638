import React from 'react'
import { useSwiper } from 'swiper/react'
import { Icon } from 'components/ui'
import styles from './carousel.module.sass'

export default function NextBtn() {
	const swiper = useSwiper()

	return (
		<div
			className={styles['nav-button']}
			onClick={() => swiper.slideNext()}
		>
			<Icon name='arrow-right' size='xl' />
		</div>
	)
}
