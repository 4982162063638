import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './button.module.sass'

const Button = ({
	children,
	size,
	view,
	className,
	fluid,
	disabled,
	type,
	onClick,
	style,
	...props
}) => {
	const classNames = cn(
		styles.button,
		styles[view],
		styles[size],
		{
			[styles.isFluid]: fluid,
		},
		className
	)

	return (
		<button
			style={style}
			className={classNames}
			type={type}
			disabled={disabled}
			onClick={!disabled ? onClick : () => {}}
			{...props}
		>
			{children}
		</button>
	)
}

Button.defaultProps = {
	view: 'primary',
	fluid: false,
	disabled: false,
	type: 'button'
}

Button.propTypes = {
	size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
	view: PropTypes.oneOf(['primary', 'secondary', 'client', 'search']),
	className: PropTypes.string,
	fluid: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	onClick: PropTypes.func
}

export default Button
