import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Text, Icon } from "components/ui";
import styles from "./filter-categories.module.sass";
import { useTranslation } from "react-i18next";

const FilterCategories = ({
														categories,
														selectedCategories,
														handlerSelectCategory,
														resetSelectedCategories,
														mobile,
													}) => {
	const { t } = useTranslation();
	if (!categories.length) return null;

	console.log(categories)

	return (
		<div
			className={cn(styles["filter-categories"], { [styles.mobile]: mobile })}
		>
			<Text size={mobile ? "md" : "lg"} color="blue">
				{t("Категории магазинов:")}
			</Text>
			<ul className={styles["filter-categories__list"]}>
				{categories.map((category, i) => (
					<li
						key={i}
						className={cn(styles["filter-categories__item"], {
							[styles.active]: selectedCategories.find(
								(v) => v._id === category._id
							),
						})}
						onClick={() => handlerSelectCategory(category)}>
						<Icon name={category.title} size="lg" color="blue" />
						<img src={category.imageUrl} alt="" />
						<Text size="sm" style={{ marginLeft: 10 }}>
							{category.title}
						</Text>
					</li>
				))}
			</ul>
			<Text
				as="p"
				size="sm"
				color="blue"
				decoration="underline"
				cursor="pointer"
				className={styles["filter-categories__reset-button"]}
				onClick={resetSelectedCategories}
			>
				{t("Сбросить")}
			</Text>
		</div>
	);
};

FilterCategories.propTypes = {
	categories: PropTypes.array.isRequired,
	selectedCategories: PropTypes.array.isRequired,
	handlerSelectCategory: PropTypes.func.isRequired,
	resetSelectedCategories: PropTypes.func.isRequired,
	mobile: PropTypes.bool,
};

export default FilterCategories;
