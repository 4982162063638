import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import HeaderCategoriesMobile from "./HeaderCategoriesMobile";
import {
	Icon,
	Text,
	Button,
	SelectLocation,
	SearchForm,
	FadeIn,
} from "components/ui";
import logo from "assets/logo-blue.svg";
import styles from "./header.module.sass";
import { useTranslation } from "react-i18next";
import { useKey } from "hooks/useKey";
import SearchContext from "../../../hooks/useContext";

const HeaderCategories = () => {
	const [isOpenLocation, setIsOpenLocation] = useState(false);
	const [isOpenSearch, setIsOpenSearch] = useState(false);
	const { deviceWidth } = useWindowSize();
	const { t } = useTranslation();
	const { setSearchValue } = useContext(SearchContext);

	const handleChange = (event) => {
		setSearchValue(event.target.value);
	};

	const closeLocation = () => setIsOpenLocation(false);
	const closeSearch = () => setIsOpenSearch(false);

	useKey("Escape", closeLocation);
	useKey("Escape", setIsOpenSearch);

	if (deviceWidth !== "large") {
		return <HeaderCategoriesMobile />;
	}
	return (
			<header className={cn(styles["header-wrapper"], styles["categories"])}>
				<div className="container h-100">
					<div className={styles.header}>
						<FadeIn
							className={styles["header__logo"]}
							fade="top"
							duration={0.5}
						>
							<Link to="/">
								<img src={logo} alt="logo" style={{ width: "130px" }} />
							</Link>
						</FadeIn>
						<div className={styles["header__location"]}>
							<FadeIn
								className={styles["header__location-icon"]}
								onClick={() => setIsOpenLocation(!isOpenLocation)}
								fade="top"
								duration={0.75}
							>
								<Icon name="location" size="md" />
								<Text size="md" style={{ marginTop: 3 }}>
									{t("Локация")}
								</Text>
							</FadeIn>
							{isOpenLocation && (
								<div className={styles["header__location-wrapper"]}>
									<SelectLocation
										active={isOpenLocation}
										close={closeLocation}
									/>
								</div>
							)}
						</div>
						<div
							className={cn(styles["header__search"], {
								[styles.active]: isOpenSearch,
							})}
						>
							{!isOpenSearch ? (
								<FadeIn className={styles.hidden} fade="top" duration={1}>
									<div
										className={styles.search}
										onClick={() => setIsOpenSearch(true)}
									>
										<Icon name="search" size="sm" />
										<Text size="md">{t("Поиск")}</Text>
									</div>
								</FadeIn>
							) : (
								<div className={styles.show}>
									<SearchForm
										searchButton={false}
										overlay={false}
										placeholder={t(
											"Поиск по категориям, названию товара в интернет магазинах"
										)}
										view="categories"
										suffix="close"
										onChange={handleChange}
									/>
									<Icon
										name="close"
										size="sm"
										className={styles.close}
										onClick={closeSearch}
										cursorPointer
									/>
								</div>
							)}
						</div>
						<FadeIn
							className={styles["header__partner-button"]}
							fade="top"
							duration={1.25}
						>
							<a href="http://partners.zumzak.ru/" target="_blank">
								<Button view="secondary">{t("Стать партнёром")}</Button>
							</a>
						</FadeIn>
					</div>
				</div>
			</header>
	);
};

export default HeaderCategories;
