import { useState, useEffect, FC } from "react";
import {
	YMaps,
	Clusterer,
	Circle,
	Placemark,
	Map,
	GeolocationControl,
} from "react-yandex-maps";

type Props = { data: any[] };

const YandexMap: FC<Props> = ({ data }) => {
	const [lat, setLat] = useState(null);
	const [long, setLong] = useState(null);
	const geolocationAPI = navigator.geolocation;

	const getUserCoordinates = () => {
		if (!geolocationAPI) {
			console.log("Geolocation API is not available in your browser!");
		} else {
			geolocationAPI.getCurrentPosition(
				(position) => {
					const { coords } = position;
					setLat(coords.latitude);
					setLong(coords.longitude);
				},
				(error) => {
					console.log("Something went wrong getting your position!");
				}
			);
		}
	};

	useEffect(() => {
		getUserCoordinates();
	}, [long, lat]);

	return (
		<div>
			<YMaps
				query={{
					lang: "en_RU",
					apikey: "9cebc737-f638-49e1-91d5-03baeb9dc4df",
					coordorder: "latlong",
				}}
			>
				<div>
					<Map
						width="100%"
						height={400}
						state={{ center: [lat, long], zoom: 14 }}
					>
						{/* <Clusterer
							options={{
								preset: "islands#invertedVioletClusterIcons",
								groupByCoordinates: false,
							}}
						></Clusterer> */}
						{/* <Circle
							geometry={{
								//@ts-ignore
								type: "Circle",
								coordinates: [lat, long],
								radius: 500,
							}}
							options={{
								fillOpacity: 1,
								strokeOpacity: 0,
								strokeWidth: 0,
								noPlacemark: false,
							}}
						/> */}
						{data.map((point, index) => (
							<Placemark
								properties={{
									balloonContentHeader: "Адрес",
									balloonContent: point.title,
								}}
								key={index}
								geometry={point.coords}
								options={{
									preset: "islands#icon",
									iconColor: "blue",
								}}
								modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
							/>
						))}
						<GeolocationControl
							options={{
								noPlacemark: false,
								float: "left",
							}}
						/>
					</Map>
				</div>
			</YMaps>
		</div>
	);
};

export default YandexMap;
