import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext,
} from "react";
import FilterCategories from "./FilterCategories";
import FilterCategoriesMobile from "./FilterCategoriesMobile";
import { Store } from "components/store";
import { Section, Text, Button, Screen, FadeIn } from "components/ui";
import styles from "./filter-categories.module.sass";
import { useTranslation } from "react-i18next";
import axios from "axios";

const StoreGroup = ({ category, name }: any) => {
	const { t } = useTranslation();
	const [shops, setShops] = useState([]);
	const shopsRef = useRef(shops);
	shopsRef.current = shops;

	const getShops = useCallback(async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/shops`, {
				offset: shopsRef.current.length,
				limit: 16,
				globalCategories: category ? [category] : [],
			});
			setShops((prevShops) => [...prevShops, ...response.data]);
			console.log(response.data, "response.data");
		} catch (error) {
			console.error(error);
		}
	}, [shopsRef, category]);

	useEffect(() => {
		getShops();
	}, [getShops]);

	return (
		<div className={styles["stores-list__group"]}>
			<Text as="p" className={styles.title}>
				{name}
			</Text>
			<div className="row row-20 row-small offset-top-20">
				{shops.map((shop, i) => (
					<div key={i} className="col-6 col-md-4 col-lg-3">
						<Store shop={shop} />
					</div>
				))}
			</div>
			{shops.length >= 16 && (
				<Button onClick={getShops} fluid>
					{t("Показать ещё магазины")}
				</Button>
			)}
		</div>
	);
};

const StoresCategories = ({ searchValue }) => {
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [shops, setShops] = useState([]);
	// const [shopCount, setShopCount] = useState(20);
	const { t } = useTranslation();
	const shopsRef = useRef(shops);
	shopsRef.current = shops;

	//change after connect to db
	const initialCategories = [
		{ id: 0, name: "Продукты", icon: "products" },
		{ id: 1, name: "Кондитерские изделия", icon: "sweet" },
		{ id: 2, name: "Безалкогольные напитки", icon: "drink" },
		{ id: 3, name: "Здоровое питание", icon: "fruit" },
		{ id: 4, name: "Красота и уход", icon: "beauty" },
		{ id: 5, name: "Товары для животных", icon: "animal" },
		{ id: 6, name: "Книги", icon: "book" },
		{ id: 7, name: "Электроника", icon: "mobile" },
		{ id: 8, name: "Дача, сад", icon: "tree" },
		{ id: 9, name: "Товары для детей", icon: "baby" },
		{ id: 10, name: "Все для дома", icon: "home" },
		{ id: 11, name: "Одежда и обувь", icon: "clothes" },
		{ id: 12, name: "Канцтовары", icon: "stationery" },
		{ id: 13, name: "Спорт", icon: "sport" },
		{ id: 14, name: "Туризм", icon: "travel" },
		{ id: 15, name: "Мебель", icon: "furniture" },
		{ id: 16, name: "Бытовая техника", icon: "appliances" },
		{ id: 17, name: "Автотовары", icon: "car" },
		{ id: 18, name: "Строительство и ремонт", icon: "repair" },
		{ id: 19, name: "Хобби и творчество", icon: "hobby" },
		{ id: 20, name: "Товары для взрослых", icon: "18-plus" },
		{ id: 21, name: "Ювелирные украшения", icon: "jewelry" },
	];

	const sixteenStores = Array(16).fill("");

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_BASE_URL}/shops/all-categories`
				);
				setCategories(response.data);
			} catch (error) {
				console.error(error);
			}
		};

		fetchCategories();
	}, []);

	console.log(categories, "categories");

	console.log(shops, selectedCategories, "shopsshops");

	const handlerSelectCategory = (category) => {
		if (!selectedCategories.find((v) => v._id === category._id)) {
			setSelectedCategories([...selectedCategories, category]);
		} else {
			setSelectedCategories(
				selectedCategories.filter((v) => v._id !== category._id)
			);
		}
	};

	const resetSelectedCategories = () => {
		setSelectedCategories([]);
	};

	// const showMoreCards = () => {
	// 	setShopCount((prevCount) => prevCount + 16);
	// };

	return (
		<Section className="z-index-1" relative small last>
			<div className="container">
				<div className="row row-20">
					<Screen size="xxl">
						<div className="col-xxl-3">
							<FadeIn fade="left">
								<div className={styles["select-categories"]}>
									<div className={styles["select-categories__background"]} />
									<FilterCategories
										categories={categories}
										selectedCategories={selectedCategories}
										handlerSelectCategory={handlerSelectCategory}
										resetSelectedCategories={resetSelectedCategories}
									/>
								</div>
							</FadeIn>
						</div>
					</Screen>
					<div className="col-xxl-9">
						<FadeIn>
							<div className={styles["stores-list"]}>
								{!selectedCategories.length ? (
									<StoreGroup
										//stores={shops}
										name={t("Магазины всех категорий:")}
									/>
								) : (
									selectedCategories.map((selectCategory, i) => (
										<StoreGroup
											key={selectCategory._id}
											category={selectCategory}
											//stores={shops}
											name={selectCategory.title}
										/>
									))
								)}
							</div>
						</FadeIn>
					</div>
				</div>
			</div>
			<Screen size="xxl" down>
				<FilterCategoriesMobile
					categories={categories}
					selectedCategories={selectedCategories}
					handlerSelectCategory={handlerSelectCategory}
					resetSelectedCategories={resetSelectedCategories}
				/>
			</Screen>
		</Section>
	);
};

export default StoresCategories;
