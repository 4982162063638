import React, { useCallback, useContext, useEffect, useState } from "react";
import { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import useOverlay from "hooks/useOverlay";
import SearchFormSection from "./SearchFormSection";
import SearchFormGroup from "./SearchFormGroup";
import SearchFormGroupItem from "./SearchFormGroupItem";
import { Button, Input, Screen, Text } from "components/ui";
import styles from "./search-form.module.sass";
import SearchContext from "../../../hooks/useContext";
import axios from "axios";

const SearchForm = forwardRef(
	(
		{
			placeholder,
			className,
			inputName,
			overlay,
			searchButton,
			searchPrefix,
			view,
			onChange,
		},
		ref
	) => {
		const { handlerOverlay } = overlay && useOverlay();
		const searchFormWrapRef = useRef(ref || null);
		const searchResultRef = useRef(null);
		const [storesProduct, setStoresProduct] = useState([]);
		const { searchValue } = useContext(SearchContext);
		console.log(searchValue, "5656565656");
		const classNames = cn(
			styles["search-form-wrapper"],
			{
				[styles["view-" + view]]: view,
			},
			className
		);

		const onClickOutsideResult = (e) => {
			const element = e.target;

			if (
				searchFormWrapRef.current &&
				!searchFormWrapRef.current.contains(element)
			) {
				e.preventDefault();
				e.stopPropagation();
				searchFormWrapRef.current.classList.remove(styles.active);
				overlay && handlerOverlay(false);
				document.body.removeEventListener("click", onClickOutsideResult);
			}
		};

		const handleSearchFocus = () => {
			document.body.addEventListener("click", onClickOutsideResult);
			searchFormWrapRef.current.classList.add(styles.active);
			overlay && handlerOverlay(true);
		};

		const onKeyDown = (e) => {
			if (e.key === "Enter") {
				e.preventDefault();
				//add methods after connect to db
			}
		};

		const getStoresProducts = async () => {
			try {
				if (searchValue.length) {
					const response = await axios.get(
						`${process.env.REACT_APP_BASE_URL}/shops/search?searchText=${searchValue}`
					);
					setStoresProduct(
						response.data[0].shops.concat(response.data[0].products)
					);
					console.log(response.data, "response.data");
				}
			} catch (error) {}
		};

		useEffect(() => {
			getStoresProducts();
		}, [searchValue]);
		console.log(storesProduct, "storesProduct");
		const ResultItem = ({ storesProduct }) => {
			//delete after connect to db
			const resultItems = ["option1", "option2", "option3"];

			return storesProduct?.map((el, i) => (
				<div
					key={i}
					style={{ display: "flex", alignItems: "center" }}
					className={styles["search-result__item"]}
				>
					<a href={`https://${el?.subDomain}.zumzak.ru/`}>
						<img
							style={{
								width: "40px",
								height: "43px",
								objectFit: "contain",
								marginRight: "10px",
							}}
							src={el?.data?.mainInformation?.logoUrl || el?.imageUrls?.[0]}
						/>
						<Text>{el?.data?.mainInformation?.title || el?.title}</Text>
					</a>
				</div>
			));
		};

		return (
			<div className={classNames} ref={searchFormWrapRef}>
				<div className={styles["search-form"]}>
					<Input.Group borderGroup={searchButton}>
						<Input
							id={inputName ? inputName : "search"}
							name={inputName ? inputName : "search"}
							placeholder={placeholder}
							prefix={searchPrefix ? "search" : ""}
							onFocus={handleSearchFocus}
							onKeyDown={onKeyDown}
							heightSize="sm"
							fluid
							onChange={onChange}
						/>
					</Input.Group>
				</div>

				<div className={styles["search-result-wrapper"]}>
					<div className={styles["search-result"]} ref={searchResultRef}>
						<ResultItem storesProduct={storesProduct} />
					</div>
				</div>
			</div>
		);
	}
);

SearchForm.Section = SearchFormSection;
SearchForm.Group = SearchFormGroup;
SearchForm.GroupItem = SearchFormGroupItem;

SearchForm.defaultProps = {
	placeholder: "Поиск...",
	overlay: true,
	searchButton: true,
	searchPrefix: true,
	view: "primary",
};

SearchForm.propsTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	overlay: PropTypes.bool,
	searchButton: PropTypes.bool,
	searchPrefix: PropTypes.bool,
	view: PropTypes.oneOf(["primary", "categories"]),
};

export default SearchForm;
