import React, { useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { HeaderCategories, FooterCategories } from "components/layout";
import { Banner, StoresCategories } from "components/categories";
import SearchContext from "../hooks/useContext";

export default function Categories() {
	const { deviceWidth } = useWindowSize();
	const [searchValue, setSearchValue] = useState("");

	const mainStyles = {
		marginTop: deviceWidth === "large" ? 76 : 65,
	};
	console.log(searchValue, "setSearchValue");

	return (
		<>
			<SearchContext.Provider value={{ searchValue, setSearchValue }}>
				<HeaderCategories />
				<main style={mainStyles}>
					<Banner />
					<StoresCategories searchValue={searchValue} />
				</main>
				<FooterCategories />
			</SearchContext.Provider>
		</>
	);
}
